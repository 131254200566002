import { ref, reactive, computed, set, watch } from 'vue'
import formUtils from '@/utils/form'
import { get } from 'lodash'

/**
   * @param {object} formRef formRef ref object
   * @param {object} formRules formRules ref object
   * @param {number} delay delay to clear validate
   */
export const onFormRulesChangeClearValidate = (formRef, formRules, delay = 0) => {
  watch(formRules, () => {
    setTimeout(() => {
      formUtils.clearValidate(formRef.value)
    }, delay)
  })
}

/**
   * @param {object} formKeyMap <原始資料 key>:<對應的 formDataKey>
   * @param {any} data 原始資料
   * @param {object} formData
   */
export const mappingSyncFormData = (formKeyMap, data, formData) => {
  for (const dataKey in data) {
    const mapFormKey = get(formKeyMap, dataKey)
    const dataValue = get(data, dataKey)
    if (mapFormKey) set(formData, mapFormKey, dataValue)
    else set(formData, dataKey, dataValue)
  }
}
export const simpleSyncFormData = (data, formData) => {
  for (const dataKey in data) {
    set(formData, dataKey, get(data, dataKey))
  }
}

export const useBaseForm = () => {
  const formData = reactive({})
  const formRef = ref(null)
  const loading = ref(false)
  const compactFormData = computed(() => { return '' })

  const initFormData = (newData) => {
    for (const attr in newData) {
      set(formData, attr, newData[attr])
    }
  }

  /**
   * @param {function} handleSubmit (data, options)
   * @param {any} data 原始資料
   * @param {object} options { emit, loading, ...custom }
   */
  const formSubmit = async (handleSubmit, data, options) => {
    if (loading.value) return
    loading.value = true
    const formPass = await formUtils.checkForm(formRef.value)
    if (!formPass) {
      loading.value = false
      return
    }
    await handleSubmit(data, options)
    loading.value = false
  }

  return {
    initFormData,
    formData,
    formRef,
    compactFormData,
    formSubmit,
    onSubmit: formSubmit,
    loading,
  }
}
