import { admin2 } from '@/api/instance'

export const GetSerialActivity = async ({
  shopId,
  start,
  limit,
  name,
  status,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivity`,
    params: {
      start,
      limit,
      name,
      status,
    },
  })
}
export const GetSerialActivityCount = async ({
  shopId,
  name,
  status,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivity/count`,
    params: {
      name,
      status,
    },
  })
}
export const FindSerialActivity = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivity/${id}`,
  })
}

export const CreateSerialActivity = async ({
  shopId,
  startAt,
  endAt,
  name,
  description,
  imageId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/serialActivity`,
    data: {
      startAt,
      endAt,
      name,
      description,
      imageId,
    },
  })
}

export const UpdateSerialActivity = async ({
  shopId,
  id,
  startAt,
  endAt,
  name,
  description,
  imageId,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/serialActivity/${id}`,
    data: {
      startAt,
      endAt,
      name,
      description,
      imageId,
    },
  })
}
export const DeleteSerialActivity = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/serialActivity/${id}`,
  })
}

// log

export const GetSerialActivityLog = async ({
  shopId,
  start,
  limit,
  type,
  MemberId,
  code,
  SerialActivityId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityLog`,
    params: {
      start,
      limit,
      type,
      MemberId,
      code,
      SerialActivityId,
    },
  })
}
export const GetSerialActivityLogCount = async ({
  shopId,
  type,
  MemberId,
  code,
  SerialActivityId,

}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityLog/count`,
    params: {
      type,
      MemberId,
      code,
      SerialActivityId,
    },
  })
}

// 序號

export const CreateSerialActivityCode = async ({
  shopId,
  serialActivityId,
  serialActivityRewardId,
  code,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/serialActivityCode`,
    data: {
      serialActivityId,
      serialActivityRewardId,
      code,
    },
  })
}
export const BatchCreateSerialActivityCode = async ({
  shopId,
  serialActivityId,
  serialActivityRewardId,
  codes,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/serialActivityCode/batchCreate`,
    data: {
      serialActivityId,
      serialActivityRewardId,
      codes,
    },
  })
}
export const SystemCreateSerialActivityCode = async ({
  shopId,
  serialActivityId,
  serialActivityRewardId,
  count,
  length,
  useUpperCase,
  useLowerCase,
  useNumber,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/serialActivityCode/systemBatchCreate`,
    data: {
      serialActivityId,
      serialActivityRewardId,
      count,
      length,
      useUpperCase,
      useLowerCase,
      useNumber,
    },
  })
}

export const FindSerialActivityCode = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityCode/${id}`,
  })
}
export const GetSerialActivityCode = async ({
  shopId,
  start,
  limit,
  SerialActivityId,
  SerialActivityRewardId,
  code,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityCode`,
    params: {
      start,
      limit,
      SerialActivityId,
      SerialActivityRewardId,
      code,
    },
  })
}

export const GetSerialActivityCodeCount = async ({
  shopId,
  SerialActivityId,
  SerialActivityRewardId,
  code,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityCode/count`,
    params: {
      SerialActivityId,
      SerialActivityRewardId,
      code,
    },
  })
}
export const UpdateSerialActivityCode = async ({
  shopId,
  id,
  code,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/serialActivityCode/${id}`,
    data: {
      code,
    },
  })
}
export const DeleteSerialActivityCode = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/serialActivityCode/${id}`,
  })
}

// 獎勵組合
export const GetSerialActivityReward = async ({
  shopId,
  SerialActivityId,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityReward`,
    params: {
      SerialActivityId,
      start,
      limit,
    },
  })
}
export const GetSerialActivityRewardCount = async ({
  shopId,
  SerialActivityId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityReward/count`,
    params: {
      SerialActivityId,
    },
  })
}
export const FindSerialActivityReward = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/serialActivityReward/${id}`,
  })
}
export const CreateSerialActivityReward = async ({
  shopId,
  serialActivityId,
  rewardType,
  rewardAmount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/serialActivityReward`,
    data: {
      serialActivityId,
      rewardType,
      rewardAmount,
    },
  })
}
export const DeleteSerialActivityReward = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/serialActivityReward/${id}`,
  })
}
export const UpdateSerialActivityReward = async ({
  shopId,
  id,
  rewardType,
  rewardAmount,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/serialActivityReward/${id}`,
    data: {
      rewardType,
      rewardAmount,
    },
  })
}
