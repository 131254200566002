<template>
  <div class="upload-button">
    <div class="upload-wrapper" :class="{ avatar: isAvatar, disabled: disabled }" :style="`width: ${width}; height: ${height}`">
      <div v-if="!image && !defaultImg" class="plus text-center">
        <i class="el-icon-plus " />
      </div>
      <img v-if="!image && defaultImg" :src="defaultImg">

      <label :data-cy="cyUploadBtn" title="點擊上傳圖片" class="input-label" :class="{disabled}">
        <input
          ref="fileInput"
          :disabled="disabled"
          type="file"
          accept=".jpg, .jpeg, .png"
          @change="imgInput"
        >
      </label>
      <img v-if="image" :data-cy="cyUploadedImg" :src="image" :class="{disabled}">
    </div>
  </div>
</template>

<script>
import { imgLoad, imgSrc } from '@/utils/helper'
export default {
  name: 'UploadButton',
  props: ['img', 'isAvatar', 'width', 'height', 'disabled', 'cyUploadBtn', 'cyUploadedImg', 'defaultImg'],
  computed: {
    image () {
      let image = ''
      // if(this.img === null) image = require("@/assets/icon/upload-placeholder.png")
      if (this.img === null) image = null
      // else image = `https://bob-image.flightgoai-service.com/display/resize/80x/${this.img.filename}`
      else image = imgSrc(300, this.img)

      return image
    },
  },

  mounted () {},
  methods: {
    imgInput (e) {
      this.$emit('change', imgLoad(e))
      this.$refs.fileInput.type = 'text'
      this.$refs.fileInput.type = 'file'
      this.$emit('action')
    },
  },
}
</script>

<style scoped lang="scss">
.upload-wrapper {
  position: relative;
  border: dashed 2px #ededed;
  height: 100%;
  border-radius: 4px;
}

.plus {
  font-size: 2rem;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  @apply bg-gray-40;
}

.avatar {
  width: 90px;
  height: 90px;
  @apply flex justify-center items-center;
  // display: grid;
  // place-content: center;
}

.input-label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

input {
  display: none;
}

img {
  @apply w-full h-full object-cover;
  border-radius: 4px;
  // width: 150px;
  // margin: auto;
}

.disabled {
  opacity: 0.5;
  @apply cursor-not-allowed;
}
</style>
